export const LOGOS = {
  BRAND: {
    LIGHT: {
      icon: "/images/logos/blue-ocean/blue-ocean/Dark-Blue-Ocean.png",
      small: "/images/logos/blue-ocean/Dark-Blue-Ocean.png",
      medium: "/images/logos/blue-ocean/Dark-Blue-Ocean.png",
      large: "/images/logos/blue-ocean/Dark-Blue-Ocean.png",
    },
    DARK: {
      icon: "/images/logos/blue-ocean/Light-Blue-Ocean.png",
      small: "/images/logos/blue-ocean/Light-Blue-Ocean.png",
      medium: "/images/logos/blue-ocean/Light-Blue-Ocean.png",
      large: "/images/logos/blue-ocean/Light-Blue-Ocean.png",
    },
  },
  BROKER1: {
    LIGHT: {
      icon: "/images/logos/blue-ocean/Dark-Blue-Ocean.png",
      small: "/images/logos/blue-ocean/Dark-Blue-Ocean.png",
      medium: "/images/logos/blue-ocean/Dark-Blue-Ocean.png",
      large: "/images/logos/blue-ocean/Dark-Blue-Ocean.png",
    },
    DARK: {
      icon: "/images/logos/blue-ocean/Light-Blue-Ocean.png",
      small: "/images/logos/blue-ocean/Light-Blue-Ocean.png",
      medium: "/images/logos/blue-ocean/Light-Blue-Ocean.png",
      large: "/images/logos/blue-ocean/Light-Blue-Ocean.png",
    },
  },
  BROKER2: {
    LIGHT: {
      icon: "",
      small: "",
      medium: "",
      large: "",
    },
    DARK: {
      icon: "",
      small: "",
      medium: "",
      large: "",
    },
  },
};
